import * as React from 'react';
import { connect } from 'react-redux';
import Calendar from 'react-big-calendar';
import moment from 'moment';
import * as R from 'ramda';
import NetworkComponentPermission
  from '@common/components/permission/network-component-permission';
import { Trans, withTranslation } from 'react-i18next';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import * as AnalyticsService from '../../../../common/services/analytics';
import Overview from '../../../../common/components/overview';
import Container from '../../../../common/components/container';
import ToolBar from '../../../../common/components/tool-bar';
import { Button } from '../../../../common/components/button';
import Modal from '../../../../common/components/modal';
import WeekSelector from '../../../../common/components/week-selector';
import ExchangeForm from '../../../flexchange/forms/add-exchange';
import ShiftDetail from '../../components/shift';
import * as schedulesSelector from '../../selectors/schedules';
import * as userSelector from '../../../core/selectors/logged-user';
import { initialValues } from '../../../flexchange/forms/add-exchange/default';
import { EComponentTypes, EPermissions } from '../../../../common/definitions';


Calendar.momentLocalizer(moment);

const createToolBar = (goToday, goPrevious, goNext, isFetching) => ({ date }) => (
  <ToolBar>
    <WeekSelector
      date={moment(date)}
      min={0}
      max={3}
      onGoToToday={goToday}
      onGoToPreviousWeek={goPrevious}
      onGoToNextWeek={goNext}
      isFetching={isFetching}
    />
  </ToolBar>
);

class SchedulesIntegrated extends React.Component {
  static defaultProps = {
    form: {},
  };

  constructor() {
    super();

    this.state = {
      week: moment().startOf('isoweek'),
      fetching: false,
      posting: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleGoToday = this.handleGoToday.bind(this);
    this.handleGoPreviousWeek = this.handleGoPreviousWeek.bind(this);
    this.handleGoNextWeek = this.handleGoNextWeek.bind(this);
    this.handleSelectShift = this.handleSelectShift.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlePostOnFlexchange = this.handlePostOnFlexchange.bind(this);
    this.handleViewShift = this.handleViewShift.bind(this);
    this.handleCreatedExchange = this.handleCreatedExchange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.deselectShift();
  }

  componentDidMount() {
    this.fetchData();
  }

  static props;

  async fetchData() {
    this.setState({ fetching: true });

    await this.props.fetchShifts();

    this.setState({ fetching: false });
  }

  handleGoToday() {
    this.setState({ week: moment() });
  }

  handleGoPreviousWeek() {
    this.setState({ week: this.state.week.subtract(1, 'week') });
  }

  handleGoNextWeek() {
    this.setState({ week: this.state.week.add(1, 'week') });
  }

  handleSelectShift(shift) {
    if (shift) {
      this.props.selectShift(shift.id);

      AnalyticsService.track(EEventNames.VIEWED_INTEGRATED_SHIFT);
    }
  }

  handleClose() {
    setTimeout(() => {
      this.props.deselectShift();
      this.setState({ posting: false });
    }, 500);
  }

  handlePostOnFlexchange(shift) {
    this.setState({ posting: true });

    if (shift) {
      this.props.initialize('add-exchange', {
        ...initialValues,
        date: moment(shift.date),
        start_time: moment(shift.start_time).format('HH:mm'),
        end_time: shift.end_time ? moment(shift.end_time).format('HH:mm') : null,
        no_end_time: !shift.end_time,
      });
    }
  }

  handleCreatedExchange(exchangeId) {
    const { network, history } = this.props;

    this.setState({ posting: false });

    return history.push(`/networks/${network.id}/flexchange/exchanges/${exchangeId}`);
  }

  handleViewShift(shift) {
    const { network, history } = this.props;

    if (!shift.exchange_id) return false;

    return history.push(`/networks/${network.id}/flexchange/exchanges/${shift.exchange_id}`);
  }

  render() {
    const { fetching, posting } = this.state;
    const {
      loggedUser,
      organisation,
      network,
      functions,
      shifts,
      shift,
      form,
      t,
    } = this.props;

    const itemModal = shift && (
      <Modal
        list
        size="small"
        title={!posting ? t('schedules:container_modal_posting_title') : null}
        className={posting ? 'modal--color-grey AddExchangeForm' : null}
        onClose={this.handleClose}
        content={posting ? (
          <ExchangeForm
            {...{
              loggedUser,
              organisation,
              network,
              functions,
              shift,
            }}
            formValues={form.values}
            onClose={this.handleCreatedExchange}
          />
        ) : (
          <div>
            <ShiftDetail shift={shift} />

            <NetworkComponentPermission
              component={EComponentTypes.FLEXCHANGE}
              networks={[network]}
            >
              {!shift.exchange_id ? (
                <div className="Shift__Flexchange">
                  <Trans i18nKey="schedules:container_modal_posting_description" />

                  <div>
                    <Button
                      type="inverted-primary"
                      onClick={() => this.handlePostOnFlexchange(shift)}
                    >
                      <Trans i18nKey="schedules:container_modal_posting_button" />
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="Shift__Flexchange">
                  <Trans i18nKey="schedules:container_modal_posted_view_description" />

                  <div>
                    <Button
                      type="inverted-primary"
                      onClick={() => this.handleViewShift(shift)}
                    >
                      <Trans i18nKey="schedules:container_modal_posted_view_button" />
                    </Button>
                  </div>
                </div>
              )}
            </NetworkComponentPermission>
          </div>
        )}
      />
    );

    return (
      <Container name="Schedules">
        {itemModal}
        <Container.Content>
          <Overview>
            <Calendar
              events={shifts}
              date={this.state.week.toDate()}
              showMultiDayTimes
              defaultView="week"
              views={['week']}
              startAccessor="start_time"
              endAccessor="end_time"
              onNavigate={R.F} // prevents error
              onSelectEvent={this.handleSelectShift}
              components={{
                toolbar: createToolBar(
                  this.handleGoToday,
                  this.handleGoPreviousWeek,
                  this.handleGoNextWeek,
                  fetching,
                ),
              }}
              formats={{
                dayFormat: 'dddd D',
                eventTimeRangeFormat: ({ start, end }) => `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`,
                eventTimeRangeStartFormat: ({ start }) => `${moment(start).format('HH:mm')} -`,
                eventTimeRangeEndFormat: ({ end }) => {
                  const endTime = moment(end);

                  if (endTime.hours() === 0 && endTime.minutes() === 0) {
                    return t('schedules:container_no_end_time');
                  }

                  return `- ${endTime.format('HH:mm')}`;
                },
              }}
            />
          </Overview>
        </Container.Content>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUser: userSelector.selected(state),
  functions: userSelector.functions(state, EPermissions.NETWORK_SCHEDULES_VIEW_ALL),
  shifts: schedulesSelector.shifts(state),
  shift: schedulesSelector.selectedShift(state),
  form: state.form['add-exchange']
});

const mapDispatchToProps = {
  initialize: require('redux-form').initialize,
  fetchShifts: require('../../actions/fetch-shifts').default,
  selectShift: require('../../actions/select-shift').default,
  deselectShift: require('../../actions/select-shift').deselectShift,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(pageWrapper(EEventNames.VIEWED_INTEGRATED_SCHEDULES)(SchedulesIntegrated)));
