import React from 'react';
import { connect } from 'react-redux';
import Default from './default';
import Integrated from './integrated';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import * as networkSelector from '../../../network/selectors/network';

require('../../styles.scss');

const Schedules = ({ history, organisation, network }) => (
  organisation.integration && organisation.integration.external_schedule
    ? <Integrated history={history} organisation={organisation} network={network} />
    : <Default />
);

const mapStateToProps = (state) => ({
  organisation: organisationSelector.selected(state),
  network: networkSelector.selected(state),
});

export default connect(mapStateToProps)(Schedules);
